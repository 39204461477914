<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <TextInput
        v-for="formItem in formItemsSorted()"
        :key="formItem._id"
        v-model="form[fieldNameFormat(formItem.name)]"
        :field="formItem"
        :vid="formItem.name"
        :label="formItem.name"
        :formData="form"
      />

      <b-button v-if="!isLoading" type="is-primary" native-type="submit">{{
        $t("join_now")
      }}</b-button>

      <b-button
        v-else
        style="padding-right: 50px !important"
        type="is-primary"
        native-type="submit"
      >
        {{ $t("please_wait") }}
        <b-loading
          :is-full-page="false"
          v-model="isLoading"
          :can-cancel="false"
        ></b-loading>
      </b-button>
    </form>
  </ValidationObserver>
</template>

<script>
/* eslint-disable no-underscore-dangle */

import TextInput from '@/components/regForm/Items/TextInput.vue';
import httpClient from '@/api/client';
import { ValidationObserver } from 'vee-validate';
import _ from 'lodash';

export default {
  components: {
    TextInput,
    ValidationObserver,
  },
  data() {
    return {
      form: {},
      isLoading: false,
      baseUrlList: {
        theatre: 'https://stage.livecasthq.com',
        webinar: 'https://webinar.livecasthq.com',
        live: 'https://live.livecasthq.com',
        irwebcast: 'https://irwebcast.livecasthq.com',
      },
    };
  },
  computed: {
    formItems() {
      return this.$root.event.regForm;
    },
  },
  methods: {
    formItemsSorted() {
      return this.formItems.sort((a, b) => a.order - b.order);
    },
    fieldNameFormat(fieldName) {
      return _.kebabCase(fieldName);
    },
    async onSubmit() {
      const self = this;
      self.isLoading = true;
      console.log(this.$root.event);
      const { emailsEnabled } = this.$root.event.registrationSettings;
      try {
        const data = {
          eventId: this.$root.event._id,
          info: this.form,
        };

        if (this.$root.event.type === 'zoomwebinar') {
          const {
            data: { registration },
          } = await httpClient.post('zoom-registration', data);

          if (emailsEnabled === true) {
            this.$router.push({
              name: 'thanks',
              params: { slug: this.$root.event._id },
            });
          } else {
            window.location.href = registration.eventUrl;
          }
        } else if (this.$root.event.type === 'custom') {
          const {
            data: { registration },
          } = await httpClient.post('registration', data);

          if (emailsEnabled === true) {
            this.$router.push({
              name: 'thanks',
              params: { slug: this.$root.event._id },
            });
          } else {
            window.location.href = `${this.$root.event.customLiveUrl}/${this.$root.event._id}?t=${registration.token}`;
            self.isLoading = false;
          }
        } else {
          const {
            data: { registration },
          } = await httpClient.post('registration', data);

          if (emailsEnabled === true) {
            this.$router.push({
              name: 'thanks',
              params: { slug: this.$root.event._id },
            });
          } else {
            const baseUrl = this.baseUrlList[this.$root.event.type];
            window.location.href = `${baseUrl}/${this.$root.event._id}?t=${registration.token}`;
            self.isLoading = false;
          }
        }
      } catch (error) {
        console.log(error);
        // message: error.response.data.message
        let { message } = error.response.data;
        const { status } = error.response.data;

        if (status === 401) {
          message = this.$t('wrong_password');
        } else {
          message = this.$t('access_forbidden');
        }

        self.$buefy.dialog.alert({
          title: this.$t('error'),
          message,
          confirmText: this.$t('okey'),
        });
        /*
        self.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          type: 'is-danger',
        });
        */
        self.isLoading = false;
      }
    },
  },
};
</script>

<style>
.field {
  margin-bottom: 1.25rem;
}
button .loading-icon,
button .loading-icon:after {
  width: 30px !important;
  height: 30px !important;
  margin-top: 5px;
}
button .loading-overlay {
  justify-content: flex-end !important;
}
</style>
