<template>
  <div id="app">
    <PageHeader @selectedLangChange="initLangChange" v-if="isReady" />
    <router-view v-if="isReady" />
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
  </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue';
import httpClient from '@/api/client';
import { loadLanguageAsync } from '@/utils/i18n-setup';
import { localize } from 'vee-validate';
import countries from '@/lang/countries.json';

export default {
  data() {
    return {
      event: null,
      isReady: false,
      selectedLang: 'en',
      isLoading: true,
    };
  },
  components: {
    PageHeader,
  },
  methods: {
    updateSelectedLang() {
      loadLanguageAsync(this.selectedLang);
      localize(this.selectedLang);
    },
    initLangChange(lang) {
      this.selectedLang = lang;
      const country = this.$root.event.regForm.find((v) => v.name === 'Country/Region');

      if (country.is_shown) {
        country.options = countries[this.selectedLang];
      }
    },
  },
  async created() {
    try {
      const res = await httpClient.get(`/event/${this.$router.currentRoute.params.slug}`);
      /* eslint-disable prefer-destructuring */
      this.$root.event = res.data.event;

      document.title = res.data.event.title;
      this.selectedLang = res.data.event.interfaceLang;

      const country = res.data.event.regForm.find((v) => v.name === 'Country/Region');

      if (country) {
        if (country.is_shown) {
          country.type = 'select_box';
          country.options = countries[this.selectedLang];
        }
      }

      this.updateSelectedLang();
      this.isReady = true;
      this.isLoading = false;
    } catch (error) {
      console.log('=>', error);
      this.isReady = true;
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss">
</style>
