var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"landing",style:(`background-image: url('${
      _vm.$root.event && _vm.$root.event.pageSettings
        ? _vm.$root.event.pageSettings.bgImgUrl
        : _vm.defaultBackgroundImg
    }')`)},[(_vm.$root.event)?_c('div',{staticClass:"container is-max-widescreen"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"columns is-centered",staticStyle:{"width":"100%"}},[(
              _vm.$root.event.pageSettings &&
              (_vm.$root.event.pageSettings.logoUrl ||
                _vm.$root.event.pageSettings.landingContent)
            )?_c('div',{staticClass:"column is-half-tablet is-three-fifths-widescreen px-5"},[(
                _vm.$root.event.pageSettings && _vm.$root.event.pageSettings.logoUrl
              )?_c('img',{attrs:{"src":_vm.$root.event.pageSettings.logoUrl}}):_vm._e(),_c('div',{staticClass:"mt-1 mb-3",domProps:{"innerHTML":_vm._s(_vm.$root.event.pageSettings.landingContent)}})]):_vm._e(),_c('div',{staticClass:"column",class:[
              _vm.$root.event.pageSettings &&
              (_vm.$root.event.pageSettings.logoUrl ||
                _vm.$root.event.pageSettings.landingContent)
                ? ' is-half-tablet is-two-fifths-widescreen'
                : 'is-full-tablet is-three-fifths-widescreen no-event-content',
            ]},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('h3',{staticClass:"is-size-5 has-text-weight-bold title"},[_vm._v(" "+_vm._s(_vm.$t("registration"))+" ")]),_c('FormBase')],1)])])])])]):_c('div',{staticClass:"no-event-fount"},[_vm._v(_vm._s(_vm.$t("no_event_found")))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }